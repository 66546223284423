.inspection-confirmation-modal {
  height: 25%;
  width: 100%;
  margin-top: 20%;
}
.inspection-confirmation-modal-paper {
  display: grid;
  grid-template-rows: .1fr 1fr;
  justify-items: center;
  width: 100%;
  height: 100%;
  padding-top: 2em;
}
.inspection-confirmation-modal-buttons {
  display: grid;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  margin-bottom: .5em;
}
.inspection-confirmation-modal-buttons-upper {
  display: grid;
  margin-top: 1em;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}
.inspection-confirmation-modal-buttons-lower {
  display: grid;
  margin: .5em;
  justify-items: center;
}