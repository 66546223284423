.open-inspection-header {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 1em;
  font-weight: bold;
}
.open-inspection-item-table-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr .1fr;
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
}
.open-inspection-delete-button {
  width: 1em;
  height: 1em;
  text-align: center;
  background-color: rgb(255, 30, 30);
  cursor: grab;
}
.open-inspection-view-button {
  width: 1em;
  height: 1em;
  text-align: center;
  background-color: rgb(30, 146, 255);
  cursor: grab;
}