.add-place-modal {
  height: 30%;
  width: 100%;
  margin-top: 20%;
}
.add-place-modal-paper {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  justify-items: center;
  width: 100%;
  height: 100%;
  padding-top: 2em;
}
.add-place-modal-buttons {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 1em;
}
.add-place-modal-paper-title {
  display: grid;
  grid-template-rows: .3fr 1fr;
  justify-items: center;
}
.add-place-modal-paper-textfield {
  display: flex;
  justify-content: center;
}