.company-container {
  height: 100vh;
  width: 100vw;
}
.company-container-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  padding: 1em;
}

div .company-container-open-inspection-button {
  width: 10em;
  background: rgb(167, 255, 130);
  border: 1px solid black;
  color: black;
  font-weight: bold;
}
div .company-container-inspection-report-button {
  width: 10em;
  background: rgb(130, 215, 255);
  border: 1px solid black;
  color: black;
  font-weight: bold;
}
div .company-container-open-inspection-button:hover {
  width: 10em;
  background: rgb(167, 255, 130);
  border: 1px solid black;
  color: black;
  font-weight: bold;
}
div .company-container-inspection-report-button:hover {
  width: 10em;
  background: rgb(130, 215, 255);
  border: 1px solid black;
  color: black;
  font-weight: bold;
}
div .company-container-create-new-inspection-button {
  width: 10em;
  background: rgb(255, 239, 121);
  border: 1px solid black;
  color: black;
  font-weight: bold;
}
div .company-container-create-new-inspection-button:hover {
  width: 10em;
  background: rgb(255, 239, 121);
  border: 1px solid black;
  color: black;
  font-weight: bold;
}