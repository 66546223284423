.landing-page-container {
margin: 0;
background-image: url("../../images/loginbackground.jpeg");
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
}

.landing-page-header {
  display: grid;
  grid-template-rows: 1fr .1fr;
height: 30vh;
background-color: rgba(255, 255, 255, 0.5);
padding: 20px;
border-radius: 10px;
box-shadow: 0
10px 50px 10px rgba(0, 0, 0, 1);
}

h1 {
color: #000000;
font-size: 3em;
margin: 0;
}

.landing-page-login-button {
background-color: #000000;
color: white;
border: none;
padding: 10px 20px;
font-size: 1em;
border-radius: 5px;
cursor: pointer;
margin-top: 20px;
transition: background-color 0.3s;
}

.landing-page-login-button:hover {
background-color: #000403;
}