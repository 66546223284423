tr .inspection-item-table-no-items {
  display: flex;
  margin-top: 0.5em;
  border-bottom: none;
}

.inspection-item-table-container {
  display: grid;
  margin-top: 1em;
  margin-bottom: 1em;
}