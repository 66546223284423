.App {
  width: 100%;
  height: 100%;
  text-align: center;
}
.main{
  width: 100%;
  height: 100%;
}
.app-menu-bar {
  display: grid;
  grid-template-columns: .25fr 1fr;
  height: 2em;
  background-color: rgb(125, 236, 108);
}
.app-menu-bar-logo {
  background-image: url("../src/images/mast.png");
  background-size: 5.5em;
  background-repeat: no-repeat;
  cursor: pointer;
}
.app-menu-bar-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  color: black;
  cursor: pointer;
  padding-left: 1em;
  border-left: .1em rgb(250, 243, 243) solid;
}
.top-bar {
  display: flex;
  justify-content: flex-end;
  padding-right: 1em;
  align-items: center;
}