div .save-button {
  background-color: rgb(118, 150, 197);
  width: 180px;
  height: 50px;
}
.save-button b {
  color: rgb(44, 44, 44);
}
.save-button .diagnoses-confirm-icon {
  color: rgb(44, 44, 44);
}
div .save-button:hover {
  background-color: rgb(118, 150, 197);
}
.save-button:hover b {
  color: rgb(229, 229, 229);
}
