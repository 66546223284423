.inspection-deital-submit-modal-list-container {
  display: grid;
  width: 100%;
}
.inspection-detail-submit-modal-paper-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 90%;
  justify-self: center;
  cursor: pointer;
}
.inspection-detail-submit-modal-paper-list-item {
  padding: .7em;
  background-color: rgb(230, 153, 153);
}