.inspection-additional-info-view-upper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.inspection-additiona-info-calendar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inspection-additional-info-view-hours {
  display: grid;
  grid-template-rows: 1fr 1fr;
}
.inspection-additional-info-view-hours-divs {
  display: flex;
  justify-content: space-around;
}
.inspection-additional-info-billinghour-textfield {
  width: 100px;
}
.inspection-additional-info-comment {
  margin-top: 2em;
}