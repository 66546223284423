.react-webcam-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsla(0, 100%, 0%, 0.85);
}
.react-webcam-capture-buttons {
  display: grid;
  grid-template-columns: .1fr .1fr;
  justify-content: space-evenly;
  align-items: flex-end;
}
div .react-webcame-capture-button {
  background-color: rgb(135, 180, 135);
}
div .react-webcame-cancel-button {
background-color: rgb(212, 163, 163);
}