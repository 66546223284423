.company-view {
  width: 100%;
  height: 100%;
}
.company-loading {
  height: 100vh;
  width: 100vw;
}
.company-loaded-upper {
  display: grid;
  grid-template-rows: 1fr 1fr;
  margin: 3vh;
}
.company-loaded-filter-icon {
  display: flex;
  margin-right: 3vh;
  justify-self: flex-end;
  cursor: pointer;
}
.company-reason-id-icon {
  display: flex;
}
.company-zip-code-icon {
  display: flex;
  align-self: flex-end;
  margin-left: 3vh;
  cursor: pointer;
}
.company-loaded-upper-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.company-loaded-upper-search {
  display: flex;
  justify-content: center;
  align-items: center;
}