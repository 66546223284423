.company-filter-modal {
  display: grid;
  justify-content: center;
  margin-top: 1em;
  height: 90%;
  width: 100%;
  overflow: auto;
}
.company-filter-modal-paper {
  display: grid;
  grid-template-rows: 1fr .1fr;
  padding: 1em;
  text-align: center;
}
.company-filter-modal-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1em;
  text-align: center;
}
.company-filter-modal-container-phone {
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding: 1em;
  text-align: center;
}
.company-filter-modal-button-area {
  display: flex;
  justify-self: center;
}
.company-filter-modal-container-upper {
  display: flex;
  align-items: center;
}
