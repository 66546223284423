div .confirm-button {
  background-color: rgb(135, 180, 135);
  width: 150px;
  height: 50px;
}
.confirm-button b {
  color: rgb(44, 44, 44);
}
.confirm-button .diagnoses-confirm-icon {
  color: rgb(44, 44, 44);
}
div .confirm-button:hover {
  background-color: rgb(135, 180, 135);
}
.confirm-button:hover b {
  color: rgb(229, 229, 229);
}
