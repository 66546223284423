.inspection-type-container {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  width: 100%;
  margin-top: 1em;
}
.inspection-type-container-header {
  display: grid;
  grid-template-rows: 1fr 1fr;
  margin-bottom: 1em;
}
.inspection-reason-selection-header {
  display: flex;
}
.inspection-type-container-header-lower {
  display: grid;
  grid-template-columns: 1fr .3fr;
  align-items: center;
  justify-self: flex-start;
  font-weight: bold;
  margin: 0em 0em 1em 1em;
}
.inspection-type-selection {
  display: grid;
  margin-left: 1em;
}
.inspection-reason-selection {
  margin: 1em 1em 3em 1em;
}
.inspection-type-container-button-area {
  display: flex;
  justify-content: space-evenly;
  padding-top: 2em;
}
.reports-icons-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  cursor: grab;
}
.reports-icons-list-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 1em;
  color: blue;
}
.inspection-type-container-animal-data {
  display: flex;
  justify-content: flex-start;
  margin: 1em;
}
.inspection-type-container-animal-data-count {
  margin-left: 0.5vh;
}
.inspection-type-container-link {
  color: rgb(70, 70, 255);
  cursor: pointer;
}