.inspection-followup-add-deviation-modal {
  height: 100%;
  width: 100%;
  overflow: auto;
}
.inspection-followup-add-deviation-modal-paper {
  display: grid;
  grid-template-rows: .1fr 1fr .1fr;
  padding: 1em;
  text-align: center;
}
.inspection-followup-add-deviation-modal-list {
  margin-top: 0.5em;
}
.inspection-followup-add-deviation-modal-list:hover {
  margin-top: 0.5em;
  background-color: rgb(221, 221, 221);
  cursor: pointer;
}
.inspection-followup-add-deviation-modal-button-area {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 2em;
}