.inspection-item-modal {
  display: flex;
  width: 100%;
  height: 100%;
}
.inspection-item-modal-paper-title {
  display: grid;
  justify-content: center;
  margin-top: 1em;
  font-weight: bold;
}
.inspection-item-modal-paper {
  display: grid;
  grid-template-rows: 0.3fr 0.4fr 0.6fr 1fr 1fr 0.3fr;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: auto;
}
.inspection-item-modal-paper-text {
  display: grid;
  grid-template-rows: .1fr .3fr .1fr;
  justify-content: center;
}
.inspection-item-photos-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
}
.inspection-item-photos-taken {
  border-top: 1px solid rgb(237, 242, 255);
  margin: 5px;
}
img {
  max-height: 10em;
  max-width: 100%;
}
.inspection-item-button-area {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  margin-bottom: 1em;
}
