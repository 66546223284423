.inspection-deviation-image-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
}
.inspection-deviation-image-select-photo {
  display: grid;
  grid-template-rows: .1fr 1fr;
}
.inspection-deviation-image-take-photo {
  display: grid;
  grid-template-rows: .1fr 1fr;
}
.inspection-deviation-image-title {
  margin-bottom: .2em;
}
.inspection-item-photos-taken {
  display: grid;
  grid-template-rows: 1fr .1fr;
  gap: 0.5em;
}