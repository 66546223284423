.company-background {
  height: 100vh;
  width: 100vw;
  background-color:white;
}
.company-image {
  display: grid;
  justify-items: center;
  height: 100vh;
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: center center;
  animation: animals 4s linear 0s infinite forwards;
}
@keyframes animals {
  0% {
    age: url("../../images/kusa1.png");
  }
  33% {
    background-image: url("../../images/kusa2.png");
  }
  66% {
    age: url("../../images/kusa3.png");
  }
  88% {
    background-image: url("../../images/kusa4.png");
  }
  100% {
    background-image: url("../../images/kusa4.png");
  }
}