.inspection-followup-add-deviation-item-modal{
  height: 100%;
  width: 100%;
  overflow: auto;
}
.inspection-followup-add-deviation-item-modal-paper {
  display: grid;
  grid-template-rows: .1fr .1fr 1fr;
  padding: 1em;
  text-align: center;
}
.inspection-followup-add-deviation-item-modal-button-area {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}
.inspection-followup-add-item-list-row {
  cursor: pointer;
}