.inspection-detail-view-container{
  display: grid;
  width: 100%;
  height: 100%;
}
.inspection-detail-view-container-upper {
  display: grid;
  grid-template-columns: 1fr 1fr .8fr;
  align-items: center;
}
.inspection-detail-view-book-icon {
  display: grid;
  justify-self: flex-end;
}
.inspection-detail-deviation-view {
  display: grid;
  justify-self: flex-start;
}
div .inspection-detail-view-book-icon:hover{
  background-color: white;
  color: rgb(78, 115, 208)
}
.inspection-deital-view-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}