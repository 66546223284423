.inspection-item-list-modal {
  display: grid;
  width: 100%;
  height: 100%;
  align-self: center;
  overflow: auto;
}
.inspection-item-list-modal-paper {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 1fr;
  justify-content: center;
  justify-items: center;
}
.inspection-item-list-modal-header {
  display: grid;
  justify-content: center;
  margin-top: 1em;
}
.inspection-item-list-button-area {
  padding: 2em;
}
.inspection-item-list-modal-title {
  display: grid;
  margin-top: .5em;
  justify-content: center;
  font-size: large;
}