.inspection-followup-icon-cell {
  display: flex;
  width: 1em;
  align-content: center;
}
.inspection-followup-all {
  display: flex;
  cursor: pointer;
}
.inspection-followup-view {
  display: grid;
  height: 100%;
}
.inspection-followup-table {
  margin-top: 1.5em;
  cursor: pointer;
}
.inspection-followup-add-deviation-button {
  margin-top: 1.5em;
}
.inspection-follow-up-table-cell:hover {
  background-color: rgb(221, 221, 221);
}