.inspection-deviation-modal-paper {
  display: grid;
  grid-template-rows: .1fr 1fr .5fr;
  height: 100%;
  width: 100%;
  text-align: center;
  overflow: auto;
}
.inspection-deviation-selected {
  display: grid;
  grid-template-rows: .1fr 1fr .1fr 1fr;
}