.inspection-followup-container {
  display: grid;
  width: 100%;
  height: 100%;
  padding-top: 1em;
}
.inspection-followup-container-stepper {
  display: flex;
  justify-content: center;
}
.inspection-followup-container-stepper-phone {
  display: flex;
  justify-self: center;
}
.inspection-followup-container-stepper-button {
  display: flex;
}
.inspection-followup-container-stepper-body {
  height: 100%;
  width: 100%;
}