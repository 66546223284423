.confirm-modal {
  height: 20%;
  width: 100%;
  margin-top: 20%;
}
.confirm-modal-paper {
  display: grid;
  grid-template-rows: .1fr 1fr;
  justify-items: center;
  width: 100%;
  height: 100%;
  padding-top: 2em;
}
.confirm-modal-buttons {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 1em;
}