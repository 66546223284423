.inspection-detail-submit-modal {
  display: grid;
  width: 90%;
  height: 100%;
  justify-self: center;
  overflow: auto;
}
.inspection-detail-submit-modal-paper-title {
  display: grid;
  font-weight: bold;
  text-align: center;
  padding: 1em;
}
.inspection-detail-submit-modal-paper {
  display: grid;
  grid-template-rows: .1fr 8fr .1fr;
  justify-content: center;
}
.inspection-detail-submit-modal-buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  background-color: white;
  padding-bottom: 1em;
}

