div .cancel-button {
  background-color: rgb(212, 163, 163);
  width: 150px;
  height: 50px;
}
.cancel-button b {
  color: rgb(44, 44, 44);
}
div .cancel-button:hover {
  background-color: rgb(212, 163, 163);
}
.cancel-button:hover b {
  color: rgb(229, 229, 229);
}
